export const openChat = (prompt: string) => {
  const chatUrl =
    `https://chat.openai.com` + `?q=${encodeURIComponent(prompt)}`;
  const isMobile = window?.innerWidth < 600;
  if (isMobile) {
    window.location.href = chatUrl;
  } else {
    window.open(chatUrl, "_blank");
  }
};

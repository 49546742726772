import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { asUntranslated } from "../../util/translate";
import { FlashcardDto } from "../../types";
import { hasEmptyImage } from "../../util/flashcards";

const IMG_TIMEOUT = 1500;

const ImgAndEngSide: React.FC<{ flashcard: FlashcardDto }> = ({
  flashcard,
}) => {
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowText(true), IMG_TIMEOUT);
    return () => clearTimeout(timer);
  }, []);

  if (!flashcard.imgUrl || hasEmptyImage(flashcard)) {
    return <Text>{asUntranslated(flashcard.polText)}</Text>;
  }

  return (
    <Container>
      {!showText ? (
        <>
          <Image
            src={flashcard.imgUrl}
            alt="flashcard"
            onClick={(e) => {
              e.stopPropagation();
              setShowText(true);
            }}
          />
        </>
      ) : (
        <Text>{asUntranslated(flashcard.polText)}</Text>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const Text = styled.p`
  text-align: center;
`;

export default ImgAndEngSide;

import { getRandomArrayElement } from "./util";
import { usePersistedState } from "../../util/react";
import { useBabblesQuery, useUpdateBabble } from "../../util/queries";
import { Link } from "react-router-dom";
import { Buttons, ModalButton } from "../ModalComponents";
import styled from "styled-components";
import { useTitle } from "../../util/title";
import { AddBabbleButton } from "./AddBabbleButton";
import { openChat } from "../../util/chat";

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  max-width: 600px;
  margin: 0 auto;

  p {
    margin: 0;
  }
`;

const Section = styled.div<{ gap: number }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gap}px;
  align-items: center;
`;

export const BabbleV2 = () => {
  useTitle("Talk!");
  localStorage.setItem("last-seen-speak", "babble");

  const [allBabbles] = useBabblesQuery(true);
  const [updateBabble] = useUpdateBabble();

  const [babbleId, setBabbleId] = usePersistedState("babble-id", "");
  const currentBabble = allBabbles.find((t) => t._id === babbleId);

  const text = currentBabble?.text || "";

  const newBabbles = allBabbles.filter(
    (babble) => babble.status === "new" && babble._id !== babbleId
  );

  const nextTopic = () => {
    if (currentBabble) {
      updateBabble({ ...currentBabble, status: "seen" });
    }

    setBabbleId(getRandomArrayElement(newBabbles)._id);
  };

  const talk = () => {
    const prompt = `I want to talk about:
${text}
First, say only Let's go!
Then I start talking to you or say "you start" or something of this sort, and then you start the conversation.
Let's keep it interesting - it's a speaking practice for me, but I want to enjoy it, too!      
`;
    openChat(prompt);
  };

  return (
    <>
      <Page>
        <h2>{text}</h2>
        <Buttons>
          <ModalButton
            onClick={talk}
            magicColor="#00BFFF"
            magicReverse
            color="white"
          >
            Let's talk!
          </ModalButton>
        </Buttons>

        <Section gap={40}>
          <ModalButton onClick={nextTopic} magicColor="gray">
            Next topic
          </ModalButton>

          <Section gap={20}>
            <p>{newBabbles.length} new topics remain</p>
            <Link to={"/config/babble"}>Show topics</Link>
            <AddBabbleButton />
          </Section>
        </Section>
      </Page>
      <div />
      <div />
    </>
  );
};

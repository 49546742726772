import { FlashcardDto } from "../types";
import { isGrammar } from "./grm";
import { RING_ACTIVE_THRESHOLD, RING_SPEAK_THRESHOLD } from "./ring";
import { cutDescriptions } from "./flashcards";

export enum Algorithm {
  NEW = "new",
  ALGORITHM_1 = "algorithm 1",
  ALGORITHM_2 = "algorithm 2",
  EARLIEST_SEEN = "earliest seen",
  HARD = "hard",
  HARDEST = "hardest",
  LAST_FLAW = "last flaw",
  PRISTINE = "pristine",
  RANDOM = "100% random",
  PROFICIENCY_ASCENDING = "prof. asc.",
  DESC = "descriptions",
  RING = "☀",
  RING_SPEAK = "🗣️",
  RING_RANDOM = "☀ random",
  RING_ALL_RANDOM = "☾ random",
  RING_BOTTOM = "☾ bottom",
  RING_SINGLEDESC = "☾ singledesc",
}

export const algorithms = [
  Algorithm.NEW,
  Algorithm.ALGORITHM_1,
  Algorithm.ALGORITHM_2,
  Algorithm.RANDOM,
  Algorithm.EARLIEST_SEEN,
  Algorithm.PRISTINE,
  Algorithm.HARD,
  Algorithm.HARDEST,
  Algorithm.LAST_FLAW,
  Algorithm.PROFICIENCY_ASCENDING,
  Algorithm.DESC,
].map((alg) => ({ value: alg, label: alg }));

export const ringAlgorithms = [
  Algorithm.RING,
  Algorithm.RING_RANDOM,
  Algorithm.RING_SPEAK,
  Algorithm.RING_ALL_RANDOM,
  Algorithm.RING_BOTTOM,
  Algorithm.RING_SINGLEDESC,
].map((alg) => ({ value: alg, label: alg }));

export function calcScore(
  flashcard: FlashcardDto,
  algorithm: Algorithm | string
) {
  switch (algorithm) {
    default:
    case Algorithm.NEW:
      return -flashcard.createTime;
    case Algorithm.ALGORITHM_1:
      return algorithm1(flashcard);
    case Algorithm.ALGORITHM_2:
      return algorithm2(flashcard);
    case Algorithm.EARLIEST_SEEN:
      return earliestSeen(flashcard);
    case Algorithm.PRISTINE:
      return pristine(flashcard);
    case Algorithm.HARD:
      return hard(flashcard);
    case Algorithm.HARDEST:
      return hardest(flashcard);
    case Algorithm.LAST_FLAW:
      return lastFlaw(flashcard);
    case Algorithm.RANDOM:
      return Math.random();
    case Algorithm.PROFICIENCY_ASCENDING:
      return flashcard.proficiency;
    case Algorithm.DESC:
      return -cutDescriptions(flashcard).length;
    case Algorithm.RING:
      return ring(flashcard);
    case Algorithm.RING_SPEAK:
      return ringSpeak(flashcard);
    case Algorithm.RING_RANDOM:
      return ringRandom(flashcard);
    case Algorithm.RING_ALL_RANDOM:
      return ringAllRandom(flashcard);
    case Algorithm.RING_BOTTOM:
      return flashcard.ring || 0;
    case Algorithm.RING_SINGLEDESC:
      return ringSingledesc(flashcard);
  }
}

function hourRandom(id: string): number {
  const now = new Date();
  const hour = now.getHours() % 12 || 12; // Convert 24-hour format to 1-12
  const date = now.getDate(); // Use the day of the month to add variability

  // Combine the ID, hour, and date into a hashable string
  const seed = `${id}-${hour}-${date}`;

  // Simple hash function to convert the seed into a number
  let hash = 0;
  for (let i = 0; i < seed.length; i++) {
    hash = (hash * 31 + seed.charCodeAt(i)) % 1000000; // Keeping it within a range
  }

  return (hash % 1000) / 1000;
}

export function algorithmFilter(
  flashcards: FlashcardDto[],
  algorithm: Algorithm
) {
  switch (algorithm) {
    case Algorithm.HARDEST:
      return flashcards.filter((flashcard) => {
        return !isNaN(hardest(flashcard));
      });
    case Algorithm.PRISTINE:
      return flashcards.filter((flashcard) => {
        return !isNaN(pristine(flashcard));
      });
    default:
      return flashcards;
  }
}

const algorithm1 = (() => {
  function calcBonus(id: string, proficiency: number, isFavourite: boolean) {
    const randomness = hourRandom(id);
    if (randomness < 0.1) {
      return 0.1;
    } else if (isFavourite && randomness < 0.33) {
      return 0.5;
    } else {
      return 1;
    }
  }

  return ({ _id, proficiency, isFavourite }: FlashcardDto) => {
    const baseProficiency =
      proficiency > 0 ? proficiency : -1 / (proficiency - 2);
    const bonus = calcBonus(_id, proficiency, isFavourite);

    return (hourRandom(_id) + Math.sqrt(baseProficiency)) * bonus;
  };
})();

const algorithm2 = ({ _id, proficiency, isFavourite }: FlashcardDto) => {
  let appliedProficiency = Math.max(0, proficiency) + 1;

  if (isFavourite && hourRandom(_id) < 0.33) {
    appliedProficiency = 1;
  }

  return hourRandom(_id) * appliedProficiency;
};

const earliestSeen = ({ updateTime, createTime }: FlashcardDto) => {
  return updateTime || createTime;
};

const hard = ({ updateTime, createTime, proficiency, flaws }: FlashcardDto) => {
  const modifyTime = updateTime || createTime;
  if (proficiency < 0 || flaws > 2) {
    return modifyTime;
  }
  return Infinity; // NaN;
};

const hardest = ({ flaws, proficiency }: FlashcardDto) => {
  // if (flaws < 2) {
  //   return NaN;
  // }
  return -(flaws * 5 - proficiency);
};

const lastFlaw = ({ lastAnswerFlaw, updateTime = 0 }: FlashcardDto) => {
  if (lastAnswerFlaw) {
    return updateTime;
  }
  return Infinity;
};

export const pristine = (flashcard: FlashcardDto) => {
  const { flaws, proficiency, createTime } = flashcard;
  const isPristine = flaws === 0 && proficiency === 0 && !isGrammar(flashcard);
  return isPristine ? createTime : Infinity; // NaN;
};

const ring = ({ ring = 0, engText }: FlashcardDto) => {
  if (ring < RING_ACTIVE_THRESHOLD) {
    return Infinity;
  }

  return -getRandomness(engText) * ring;
};

function getRandomness(name: string) {
  const textNum = textToNumber(name);
  const dayNum =
    textNum * (new Date().getUTCDate() + new Date().getHours() + 1);

  const threeDigitNum = Number(String(dayNum).substring(0, 3));
  return threeDigitNum;
}

const ringSpeak = ({ ring = 0, engText }: FlashcardDto) => {
  if (ring < RING_SPEAK_THRESHOLD) {
    return Infinity;
  }

  return ring;
};

const ringRandom = ({ _id, ring = 0 }: FlashcardDto) => {
  if (ring < RING_ACTIVE_THRESHOLD) {
    return Infinity;
  }

  return ring * hourRandom(_id);
};

const ringAllRandom = ({ _id, ring = 0 }: FlashcardDto) => {
  return ring * hourRandom(_id);
};

const ringSingledesc = (flashcard: FlashcardDto) => {
  const { ring = 0 } = flashcard;
  if (cutDescriptions(flashcard).length === 1) {
    return -ring;
  }
  return Infinity;
};

function textToNumber(str: string): number {
  let result = 0;
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i);
    // You can use various operations here. For simplicity, let's just add them.
    result += charCode;
  }
  return result;
}

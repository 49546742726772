/**
 * Upload an image to the server with optional resizing parameters.
 * If no stored password is found, prompt the user for one.
 * On success, store the password in localStorage for future calls.
 *
 * @param file   The image file to upload
 * @param options Optional resizing { width?: number; height?: number }
 * @returns An object containing the server URL on success,
 *          a local preview URL of the image,
 *          and an error message if something failed.
 */
export async function uploadImage(
  file: File,
  options?: { width?: number; height?: number }
): Promise<{
  serverUrl: string | null;
  previewUrl: string;
  error: string | null;
}> {
  const SERVER_URL = "https://sir.dziegelewski.com/upload";
  // const SERVER_URL = "http://localhost:3003/upload";

  // Create a local preview URL (so you can show the image even if upload fails)
  const previewUrl = URL.createObjectURL(file);

  // Check localStorage for a saved password
  let storedPassword = localStorage.getItem("uploadPassword");

  // Prompt if none found
  if (!storedPassword) {
    storedPassword = prompt("Enter your upload password:") || "";
    if (!storedPassword) {
      return {
        serverUrl: null,
        previewUrl,
        error: "Upload canceled: No password provided.",
      };
    }
  }

  try {
    // Build the query params if width or height is provided
    const { width, height } = options || {};
    const queryParams = new URLSearchParams();
    if (width) queryParams.append("width", width.toString());
    if (height) queryParams.append("height", height.toString());

    // Prepare the FormData
    const formData = new FormData();
    formData.append("image", file);

    // Make the request
    const response = await fetch(`${SERVER_URL}?${queryParams.toString()}`, {
      method: "POST",
      headers: {
        "x-upload-password": storedPassword,
      },
      body: formData,
    });

    // Handle errors
    if (!response.ok) {
      if (response.status === 401) {
        alert("Incorrect password!");
        localStorage.removeItem("uploadPassword");
      }
      throw new Error(`Upload failed with status ${response.status}`);
    }

    // Parse the JSON response (expected: { url: string })
    const data = await response.json();

    // Save the password to localStorage for future uploads
    localStorage.setItem("uploadPassword", storedPassword);

    return {
      serverUrl: data.url || null,
      previewUrl,
      error: null,
    };
  } catch (err: unknown) {
    let errorMessage = "An unknown error occurred.";
    if (err instanceof Error) {
      errorMessage = err.message;
    }

    return {
      serverUrl: null,
      previewUrl,
      error: errorMessage,
    };
  }
}

import { usePersistedState } from "../../util/react";
import { Completion, Side } from "../../types";
import { useContext, useState } from "react";
import { AppContext } from "../AppContext";

export const useControlsState__ = () => {
  const startSideState = usePersistedState<Side>("side", "eng");
  const completionState = usePersistedState<Completion>(
    "completion",
    "challenge"
  );
  const contextsState = usePersistedState<string[]>("contexts", []);
  const searchState = useState<string>("");

  const searchEverywhereState = usePersistedState("search-everywhere", false);
  const ringFavouriteState = usePersistedState("ring-favourite", true);
  const asyncLoaderState = usePersistedState("async-loader", false);
  const speakThresholdState = usePersistedState("speak-threshold", false);
  const voiceControlState = usePersistedState("voice-control", false);
  const writingAssistantState = usePersistedState("writing-assistant", false);
  const autoDictState = usePersistedState("auto-dict", false);
  const reverseState = usePersistedState("reverse", false);

  return {
    silentState: usePersistedState("silent", false),
    startSideState,
    searchEverywhereState,
    ringFavouriteState,
    asyncLoaderState,
    speakThresholdState,
    voiceControlState,
    writingAssistantState,
    autoDictState,
    completionState,
    contextsState,
    searchState,
    reverseState,
  };
};

export type ControlsState = ReturnType<typeof useControlsState__>;

export const useExtraControls__ = () => {
  const [extraControlsVisible, setExtraControlsVisible] = useState(false);
  const closeExtraControls = () => setExtraControlsVisible(false);
  const showExtraControls = () => setExtraControlsVisible(true);

  return [extraControlsVisible, closeExtraControls, showExtraControls] as const;
};

export const useControls = () => {
  const { controlsState } = useContext(AppContext);
  return controlsState;
};

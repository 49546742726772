import { PRONUNCIATION_CONTEXT } from "../components/Controls/consts";

export const priorityContexts = [PRONUNCIATION_CONTEXT, "NTFL", "SELF"];
export const hiddenContexts = [
  "BA",
  "HP",
  "FI1",
  "FI2",
  "FI3",
  "FI4",
  "P5",
  "BOOK",
  "BOOK2",
  "BOOK3",
  "BOOK4",
  "BOOK5",
  "BOOK6",
  "COL",
  "IDIOM",
  "SPK",
  "DUNG",
];

const contextAliases: Record<string, string> = {
  BOOK: "∇HARA",
  BOOK2: "∇MIL",
  BOOK3: "∇STON",
  BOOK4: "∇SOW",
  BOOK5: "∇SHAN",
  BOOK6: "∇GREY",
};

export const getContextName = (context: string) => {
  return contextAliases[context] || context;
};

import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FlashcardCreateDto, FlashcardDto } from "../../types";
import { asUntranslated, isTranslated, translate } from "../../util/translate";
import { useDebouncedCallback } from "use-debounce";
import { Input } from "../Input";
import { ContextInput } from "../ContextInput";
import { DictLink } from "../DictLink";
import { useNavigate } from "react-router";
import { wait, waitUntilValue } from "../../util/wait";
import { AppContext } from "../AppContext";
import {
  EMPTY_IMAGE,
  pullTransientFlashcardData,
  searchFlashcards,
} from "../../util/flashcards";
import { FlashcardList } from "../FlashcardList";
import { TranslationLink } from "../TranslationLink";
import { ExampleGenerator } from "./ExampleGenerator";
import { GRAMMAR_CONTEXT } from "../Controls/consts";
import { CollectionToggleButton } from "../CollectionToggleButton";

import { Buttons, ModalButton } from "../ModalComponents";
import { FlashcardSimpleDto, MoreMenu } from "../Flashcard/MoreMenu";
import {
  Adornments,
  AssistantClose,
  AssistantModal,
  DesccriptionInside,
  DesctiptionInsideLeft,
  DesctiptionInsideLeft2,
  ExampleGeneratorButton,
  FirstExample,
  ForceTranslateButton,
  SearchIndicator,
  StyledForm,
  StyledFormWrapper,
} from "./style";
import { WritingAssistant } from "./WritingAssistant";
import { useAssistantText } from "./useAssistantText";
import { useExtraExample } from "./useExtraExample";
import { useSaveOffline } from "../../util/offline";
import { useMinScreen } from "../../util/react";
import { AutoDictIframe } from "./AutoDictIframe";
import { isGrammar } from "../../util/grm";
import {
  uploadClipboardImage,
  useClipboardImageUrl,
} from "../../util/clipboard";
import { openChat } from "../../util/chat";
import { useControls } from "../Controls/hooks";
import { speak } from "../../util/speech";
import { ReadOut } from "../ReadOut";

interface FormUtil {
  reset: () => void;
  setFieldValue: (fieldName: keyof FlashcardCreateDto, value: string) => void;
  silent: boolean;
}

interface FlashcardFormProps {
  onSubmit: (flashcard: FlashcardCreateDto, formUtil: FormUtil) => void;
  submitText: string;
  isAdding?: boolean;
  autoListen?: boolean;

  flashcard?: FlashcardCreateDto;
  onDelete?: () => void;
}

export const FlashcardForm: FC<FlashcardFormProps> = (props) => {
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(-1);
  };

  const { flashcard, submitText, onSubmit, onDelete, isAdding, autoListen } =
    props;

  const { handleSubmit, register, getValues, setValue, watch, reset } =
    useForm<FlashcardCreateDto>({
      defaultValues: flashcard || {
        engText: "",
        polText: "",
        description: "",
        context: localStorage.getItem("lastContext") || "",
        proficiency: 0,
        category: "",
        extraInfo: "",
        polDescription: "",
        newFlashcardCollections: isAdding ? [] : undefined,
      },
    });

  const [translationInProgress, setTranslationInProgress] = useState(false);
  const [searched, setSearched] = useState<FlashcardDto[]>([]);
  const [showSearch, setShowSearch] = useState(false);
  const silent = useControls().silentState[0];

  const formUtil: FormUtil = {
    reset: () => {
      setSearched([]);
      reset();
    },
    setFieldValue: (fieldName: keyof FlashcardCreateDto, value) =>
      setValue(fieldName, value),
    silent,
  };

  useEffect(() => {}, [!isAdding]);

  const hasRun = useRef(false);

  useEffect(() => {
    if (!hasRun.current) {
      hasRun.current = true;
      if (silent) return;
      speak(getValues().engText, "en-GB");
    }
  }, [!isAdding]);

  const translateEngToPol = async (force?: boolean) => {
    const { engText, polText } = getValues();

    if (!engText || translationInProgress) {
      return;
    }

    setTranslationInProgress(true);
    translate(engText, "pl", true)
      .then((translatedEngText) => {
        const shouldInsertTranslation =
          force || !polText || isTranslated(polText);
        const translationIsAlreadyOk =
          asUntranslated(polText) === asUntranslated(translatedEngText);
        if (shouldInsertTranslation && !translationIsAlreadyOk) {
          setValue("polText", translatedEngText);
        }
      })
      .finally(() => setTranslationInProgress(false));
  };

  const debouncedTranslateEngToPol = useDebouncedCallback(
    () => translateEngToPol(),
    1000
  );

  const translatePolToEng = () => {
    const { polText } = getValues();
    translate(polText, "en").then((translation) => {
      setValue("engText", translation);
    });
  };

  const engText = watch("engText");
  const polText = watch("polText");
  const descriptionText = watch("description");
  const context = watch("context");
  const { flashcards } = useContext(AppContext);

  const debouncedSearch = useDebouncedCallback(() => {
    if (engText.length < 3) {
      setSearched([]);
    } else {
      setSearched(searchFlashcards(flashcards, engText));
    }
  }, 400);

  const duplicates = searched.filter((f) => f.engText === engText);

  const onChangeEngText = () => {
    debouncedTranslateEngToPol();
    debouncedSearch();
  };

  const [showExampleGenerator, setShowExampleGenerator] = useState(false);
  const generateInBackground = !isAdding && !flashcard?.description;
  const [descriptionGlowing, setDescriptionGlowing] = useState(0);

  const { firstExample, onExtraExample } = useExtraExample(
    descriptionText,
    () => setValue("description", "")
  );

  const _onSubmit = handleSubmit(async (values) => {
    if (!values.polText) {
      const getPolText = () => getValues().polText;
      values.polText = await waitUntilValue(getPolText, 5000);
    }

    if (firstExample) {
      values.description = `${firstExample} || ${values.description}`;
    }

    values.description = values.description.trim();

    onSubmit(values, formUtil);
  });

  const hasGrammarContext = context.includes(GRAMMAR_CONTEXT);

  useEffect(() => {
    const storedData = pullTransientFlashcardData();
    if (storedData) {
      setValue("engText", storedData.engText);
      setValue("context", storedData.context);
      if (storedData.polText) {
        setValue("polText", storedData.polText);
      } else {
        translateEngToPol();
      }
      debouncedSearch();
    }
  }, []);

  const [menuFlashcard, setMenuFlashcard] = useState<FlashcardSimpleDto | null>(
    null
  );

  const { assistantText, handleDescriptionKeyDown, stopAssistant } =
    useAssistantText(descriptionText);

  const { saveOffline } = useSaveOffline();

  const controlsState = useControls();
  const autoDict =
    useMinScreen(600) && !isAdding && controlsState.autoDictState[0];

  const openImageSearch = () => {
    window.open(
      `https://www.google.com/search?q=${engText}&tbm=isch`,
      "_blank"
    );
  };

  const openAIImageCreator = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    // wait, because otherwise the modal closes (?)
    await wait(100);
    openChat(
      `I'm making a flashcard for English word/phrase:
${engText}
it's Polish translation is:
${polText}

I'll need to create an image for it, so I don't need a Polish translation - I just look at the picture and know what it means.
Think of 3 different ideas how it should be presented in an image.
Present the ideas to me.

That's it for now!

Later, I'll choose one of the ideas (or go for something even different) and ask you to make a picture of one of them.
Image should be simple, clear and easy to understand.
Use a quite simple style, but don't overdo.
DON NOT use any words in the image. Avoid words in the image.
`
    );
  };

  const imageUrl = watch("imgUrl");
  const clipboardImageUrl = useClipboardImageUrl();

  const onPasteImage = async () => {
    const uploaded = await uploadClipboardImage();
    if (uploaded?.serverUrl) {
      setValue("imgUrl", uploaded.serverUrl);
    }
  };

  return (
    <StyledFormWrapper autoDict={autoDict}>
      {imageUrl && imageUrl !== EMPTY_IMAGE && (
        <img
          src={imageUrl}
          alt="img"
          style={{ maxWidth: "100%", maxHeight: "200px", margin: "0 auto" }}
        />
      )}
      <StyledForm onSubmit={_onSubmit}>
        <Input
          placeholder="Eng text"
          register={register("engText", {
            onBlur: () => translateEngToPol(),
            onChange: onChangeEngText,
          })}
          autoCapitalize="off"
          autoComplete="off"
          autoFocus={isAdding}
          big
          required
          voiceControlled
          adornment={
            <Adornments>
              {searched.length > 0 && (
                <SearchIndicator
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowSearch((val) => !val);
                  }}
                  active={showSearch}
                >
                  {searched.length}
                </SearchIndicator>
              )}
              <ReadOut text={engText} />
              {engText ? <DictLink engText={engText}>📖</DictLink> : null}
            </Adornments>
          }
        />

        {isAdding && duplicates.length > 0 && (
          <FlashcardList
            flashcards={duplicates}
            startSide="dual"
            padding="none"
          />
        )}
        {showSearch && searched.length > 0 && (
          <FlashcardList flashcards={searched} startSide="eng" padding="none" />
        )}

        {!hasGrammarContext && (
          <Input
            placeholder="Pol text"
            register={register("polText")}
            autoCapitalize="off"
            autoComplete="off"
            disabler
            id="polText"
            adornment={
              <ForceTranslateButton
                onClick={(e) => {
                  e.stopPropagation();

                  if (engText) {
                    translateEngToPol(true);
                  } else {
                    translatePolToEng();
                  }
                }}
                type="button"
              >
                !
              </ForceTranslateButton>
            }
          />
        )}
        {assistantText && (
          <AssistantModal>
            <WritingAssistant
              text={assistantText}
              crucialPhrase={
                isGrammar(flashcard) ? undefined : flashcard?.engText
              }
            />
            <AssistantClose onClick={stopAssistant} type="button">
              ˣ
            </AssistantClose>
          </AssistantModal>
        )}

        <Input
          register={register("imgUrl")}
          disabler
          autoComplete="off"
          placeholder="Img"
          adornment={
            <>
              {!imageUrl && clipboardImageUrl && (
                <span
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    marginRight: "60px",
                  }}
                  onClick={onPasteImage}
                >
                  <img
                    src={clipboardImageUrl}
                    alt="Clipboard Preview"
                    style={{ maxHeight: 35 }}
                  />
                </span>
              )}
              <span
                style={{ cursor: "pointer", marginRight: "30px" }}
                onClick={openAIImageCreator}
              >
                📀
              </span>
              <span style={{ cursor: "pointer" }} onClick={openImageSearch}>
                🔍
              </span>
            </>
          }
        />

        <ContextInput
          placeholder="Kontekst"
          register={register("context")}
          disabler
          autoCapitalize="off"
          setContext={(ctx) => setValue("context", ctx)}
          value={watch("context")}
          adornment={
            flashcard?._id && (
              <CollectionToggleButton flashcardId={flashcard._id} />
            )
          }
        />
        {firstExample && <FirstExample>{firstExample}</FirstExample>}
        <Input
          textAreaRows={hasGrammarContext ? 12 : 4}
          register={register("description")}
          onKeyDown={handleDescriptionKeyDown}
          autoComplete="off"
          disabler={!hasGrammarContext}
          type="textarea"
          glowing={descriptionGlowing > 0}
          onSubmit={_onSubmit}
          voiceControlled
        >
          <>
            <DesctiptionInsideLeft>
              <ReadOut text={descriptionText} />
            </DesctiptionInsideLeft>
            <DesctiptionInsideLeft2>
              {descriptionText && !firstExample && (
                <ExampleGeneratorButton onClick={onExtraExample}>
                  +
                </ExampleGeneratorButton>
              )}
            </DesctiptionInsideLeft2>
            <DesccriptionInside>
              {descriptionText && <TranslationLink text={descriptionText} />}
              <ExampleGeneratorButton
                onClick={(e) => {
                  e.stopPropagation();
                  (document.activeElement as any)?.blur?.();
                  setShowExampleGenerator(true);
                }}
              >
                📀
              </ExampleGeneratorButton>
            </DesccriptionInside>
          </>
        </Input>

        <Buttons>
          <ModalButton type="submit" disabled={translationInProgress} flex={4}>
            {translationInProgress ? "Tłumaczenie..." : submitText}
          </ModalButton>
          {Boolean(isAdding && translationInProgress) && (
            <ModalButton
              type="button"
              onClick={async (e) => {
                await saveOffline({ engText, context });
                setValue("engText", "");
              }}
              flex={1}
            >
              Zapisz offline
            </ModalButton>
          )}
          {Boolean(isAdding && descriptionText) && (
            <ModalButton
              type="submit"
              onClick={(e) => {
                if (!polText.endsWith("*")) {
                  setValue("polText", polText + "*");
                }
              }}
              disabled={translationInProgress}
              flex={1}
            >
              {submitText} (ćśśś)
            </ModalButton>
          )}
        </Buttons>
        <Buttons>
          <ModalButton type="button" onClick={navigateBack}>
            Wróć
          </ModalButton>
          {flashcard?._id && (
            <ModalButton
              type="button"
              flex={0}
              onClick={() =>
                setMenuFlashcard({
                  _id: flashcard._id!,
                  engText,
                  polText,
                  context,
                  description: descriptionText,
                })
              }
            >
              🪄
            </ModalButton>
          )}
          {onDelete && (
            <ModalButton type="button" onClick={onDelete} color="red">
              Usuń
            </ModalButton>
          )}
        </Buttons>
      </StyledForm>
      {autoDict && (
        <AutoDictIframe engText={engText} flashcardId={flashcard?._id} />
      )}
      {(showExampleGenerator || generateInBackground) && (
        <ExampleGenerator
          flashcard={getValues()}
          onSelectExample={(example) => {
            setValue("description", example);
            setDescriptionGlowing((val) => val + 1);
            setTimeout(() => setDescriptionGlowing((val) => val - 1), 1500);
          }}
          onClose={() => setShowExampleGenerator(false)}
          workInBackground={!showExampleGenerator && generateInBackground}
        />
      )}
      {menuFlashcard && (
        <MoreMenu
          flashcard={menuFlashcard}
          onClose={() => setMenuFlashcard(null)}
        />
      )}
    </StyledFormWrapper>
  );
};

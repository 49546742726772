export interface CurriculumItem {
  id: string;
  content: (() => string) | (() => Promise<string>);
}

export const intro = `I'm learning English and I'll be passing C2 exam soon, aiming for max proficiency (so don't go easy on me if I make mistakes).\n`;
const oneAtATime = `\nGive me Polish sentences to translate, one at a time.\n`;

export const items = (): CurriculumItem[] => [
  {
    id: "pronouns",
    content: () =>
      intro + `I'm sometimes struggling with advanced pronouns` + oneAtATime,
  },
  {
    id: "time",
    content: () =>
      intro +
      `I'm sometimes struggling with advanced time expressions` +
      oneAtATime,
  },
  {
    id: "advanced-conditionals",
    content: () =>
      intro +
      `I'm sometimes struggling with advanced conditionals` +
      oneAtATime,
  },
  {
    id: "phrasal-verbs",
    content: () =>
      intro +
      `I'm sometimes struggling with advanced phrasal verbs` +
      oneAtATime,
  },
  {
    id: "idioms",
    content: () =>
      intro +
      `I'm sometimes struggling with advanced idiomatic expressions` +
      oneAtATime,
  },
  {
    id: "reported-speech",
    content: () =>
      intro +
      `I'm sometimes struggling with advanced reported speech` +
      oneAtATime,
  },
  {
    id: "inversions",
    content: () =>
      intro +
      `I'm sometimes struggling with using inversions correctly` +
      oneAtATime,
  },
  {
    id: "articles",
    content: () =>
      intro +
      `I'm sometimes struggling with advanced use of articles (a/an/the)` +
      oneAtATime,
  },
  {
    id: "gerund-infinitive",
    content: () =>
      intro +
      `I'm sometimes struggling with gerund vs. infinitive usage` +
      oneAtATime,
  },
  {
    id: "collocations",
    content: () =>
      intro +
      `I'm sometimes struggling with advanced collocations` +
      oneAtATime,
  },
  {
    id: "subjunctive",
    content: () =>
      intro + `I'm sometimes struggling with the subjunctive mood` + oneAtATime,
  },
  {
    id: "passives",
    content: () =>
      intro +
      `I'm sometimes struggling with advanced passive constructions` +
      oneAtATime,
  },
  {
    id: "emphasis",
    content: () =>
      intro +
      `I'm sometimes struggling with emphatic structures like cleft sentences` +
      oneAtATime,
  },
  {
    id: "discourse-markers",
    content: () =>
      intro +
      `I'm sometimes struggling with advanced discourse markers` +
      oneAtATime,
  },
  {
    id: "conditionals-mixed",
    content: () =>
      intro +
      `I'm sometimes struggling with mixed conditionals and their nuances` +
      oneAtATime,
  },
];

import styled, { css } from "styled-components";
import { FlashcardCreateDto } from "../types";
import { FC } from "react";

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  gap: 20px;
  padding: 10px;
  box-sizing: border-box;
`;
export const ModalButton = styled.button<{
  color?: string;
  magicColor?: string;
  magicReverse?: boolean;
  flex?: number;
}>`
  flex: ${(props) => props.flex ?? 1};
  display: block;
  padding: 18px;
  color: ${(props) => props.color || "black"};
  background: rgba(206, 213, 254, 0.5);
  border: 1px solid rgba(198, 206, 251, 0.5);

  &:hover {
    background: rgba(188, 197, 251, 0.5);
  }

  transition: background-color 0.1s;
  cursor: pointer;

  ${(props) =>
    props.magicColor &&
    css`
      background-color: ${!props.magicReverse ? "white" : props.magicColor};
      border: 1px solid ${props.magicColor};
      transition: all 0.1s;

      &:hover {
        background-color: ${!props.magicReverse ? props.magicColor : "white"};
        color: ${!props.magicReverse ? "white" : props.magicColor};
      }
    `}
`;

interface ModalFlashcardHeadingProps {
  flashcard: FlashcardCreateDto;
}

const StyledModalFlashcardHeading = styled.div`
  font-size: 1.2rem;

  p {
    margin: 0 0 10px 0;
    text-align: center;
  }
`;

export const ModalFlashcardHeading: FC<ModalFlashcardHeadingProps> = ({
  flashcard,
}) => {
  return (
    <StyledModalFlashcardHeading>
      <p>
        <b>{flashcard.engText}</b>
      </p>
      <p>{flashcard.polText}</p>
    </StyledModalFlashcardHeading>
  );
};

import { useEffect, useState } from "react";
import { getUrlState } from "./url";
import { FlashcardDto } from "../types";

export type PersistedState<T> = readonly [T, (value: T) => void];

export type PersistedKey =
  | "side"
  | "new-first"
  | "search-everywhere"
  | "completion"
  | "contexts"
  | "heat"
  | "quizometer"
  | "shownStory"
  | "currentCollectionName"
  | "search"
  | "uses"
  | "algorithm"
  | "ring-algorithm"
  | "algorithm-picker"
  | "topic-id"
  | "topic-ids"
  | "babble-id"
  | "last-created-ids"
  | "play-mode"
  | "current-example"
  | "quiz-autogenerate-prepositions"
  | "quiz-include-autogen-prepositions"
  | "quiz-view-prepositions"
  | "quiz-algorithm-prepositions"
  | "quiz-autogenerate-gerunds"
  | "quiz-include-autogen-gerunds"
  | "quiz-view-gerunds"
  | "quiz-algorithm-gerunds"
  | "quiz-autogenerate-articles"
  | "quiz-include-autogen-articles"
  | "quiz-view-articles"
  | "quiz-algorithm-articles"
  | "quiz-autogenerate-varia"
  | "quiz-include-autogen-varia"
  | "quiz-view-varia"
  | "quiz-algorithm-varia"
  | "quiz-autogenerate-live"
  | "quiz-include-autogen-live"
  | "quiz-view-live"
  | "quiz-algorithm-live"
  | "quiz-mix-proportions"
  | "audio-player-mode"
  | "ring-favourite"
  | "async-loader"
  | "speak-threshold"
  | "voice-control"
  | "auto-dict"
  | "writing-assistant"
  | "live-mode"
  | "save-offline"
  | "reverse"
  | "curriculum"
  | "silent";

export const usePersistedState = <T>(
  key: PersistedKey,
  defaultValue: T,
  storage: Storage = localStorage
): PersistedState<T> => {
  const [state, setState] = useState<T>(() => {
    if (typeof window === "undefined") {
      return defaultValue;
    }

    const persistedValue = getPersistedValue(key, storage);

    if (persistedValue) {
      try {
        return JSON.parse(persistedValue);
      } catch {
        return persistedValue;
      }
    }
    return defaultValue;
  });
  const setPersistedState = (value: T) => {
    setPersistedValue(key, value, storage);
    setState(value);
  };
  return [state, setPersistedState] as const;
};

export const setPersistedValue = (
  key: PersistedKey,
  value: any,
  storage = localStorage
) => {
  storage.setItem(key, JSON.stringify(value));
};

export const getPersistedValue = (
  key: PersistedKey,
  storage = localStorage
) => {
  return getUrlState(key, false) || storage.getItem(key);
};

export const useIsMobile = (maxWidth = 800) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= maxWidth);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= maxWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [maxWidth]);

  return isMobile;
};

export const useMinScreen = (minWidth = 800) => {
  const [isMinScreen, setIsMinScreen] = useState(window.innerWidth >= minWidth);

  const handleResize = () => {
    setIsMinScreen(window.innerWidth >= minWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [minWidth]);

  return isMinScreen;
};

// copy to clipboard
export function copyToClipboard(text: string) {
  const el = document.createElement("textarea");
  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
}

interface InsightReadyEvent {
  flashcardId: string;
}

export const dispatchInsightEvent = (flashcardId: string) => {
  const customEvent = new CustomEvent<InsightReadyEvent>("insightReady", {
    detail: {
      flashcardId,
    },
  });
  window.dispatchEvent(customEvent);
};

export const useInsightEvent = (flashcard: FlashcardDto) => {
  const [hasInsight, setHasInsight] = useState(
    (flashcard.insightLevel || 0) > 0
  );

  const onInsightReady = (event: CustomEvent<InsightReadyEvent>) => {
    if (event.detail.flashcardId === flashcard._id) {
      setHasInsight(true);
    }
  };

  const eventId = `insightReady-${flashcard._id}`;

  useEffect(() => {
    // @ts-ignore
    window.addEventListener("insightReady", onInsightReady);
    return () => {
      // @ts-ignore
      window.removeEventListener("insightReady", onInsightReady);
    };
  }, [eventId]);

  return hasInsight;
};

export function useIsInputActive(): boolean {
  const [isInputActive, setIsInputActive] = useState(false);

  useEffect(() => {
    const checkActiveInput = () => {
      // Check if the active element is an input, select, or textarea.
      const active = document.activeElement;
      const isActiveInput =
        active &&
        (active.tagName === "INPUT" ||
          active.tagName === "TEXTAREA" ||
          active.tagName === "SELECT");
      setIsInputActive(!!isActiveInput);
    };

    // Check right away
    checkActiveInput();

    // Add event listeners
    window.addEventListener("focus", checkActiveInput, true);
    window.addEventListener("blur", checkActiveInput, true);

    // Cleanup
    return () => {
      window.removeEventListener("focus", checkActiveInput, true);
      window.removeEventListener("blur", checkActiveInput, true);
    };
  }, []);

  return isInputActive;
}

export function useActiveInput() {
  const [activeInput, setActiveInput] = useState<
    Element | HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | null
  >(null);

  useEffect(() => {
    const checkActiveInput = () => {
      const active = document.activeElement;
      const isActiveInput =
        active &&
        (active.tagName === "INPUT" ||
          active.tagName === "TEXTAREA" ||
          active.tagName === "SELECT")
          ? active
          : null;

      setActiveInput(isActiveInput);
    };

    // Check right away
    checkActiveInput();

    // Add event listeners
    window.addEventListener("focus", checkActiveInput, true);
    window.addEventListener("blur", checkActiveInput, true);

    // Cleanup
    return () => {
      window.removeEventListener("focus", checkActiveInput, true);
      window.removeEventListener("blur", checkActiveInput, true);
    };
  }, []);

  return activeInput;
}

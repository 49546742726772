import { usePersistedState } from "../../util/react";
import { ModalButton } from "../../components/ModalComponents";
import styled from "styled-components";
import { useTitle } from "../../util/title";
import { CurriculumItem, items } from "./items";
import { useLearnSomethingNew } from "./learnSomethingNew";
import React from "react";

import { openChat } from "../../util/chat";

const Page = styled.div`
  padding: 10px 20px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

export const CurriculumPage = () => {
  useTitle("Curriculum");
  const [state, setState] = usePersistedState<Record<string, number>>(
    "curriculum",
    {}
  );
  const incrementCurriculum = (id: string) => {
    setState({
      ...state,
      [id]: (state[id] || 0) + 1,
    });
  };

  const onClickItem = async (item: CurriculumItem) => {
    const prompt =
      (await item.content()) + `\n[Some randomness: ${Math.random()}]`;
    incrementCurriculum(item.id);
    openChat(prompt);
  };

  const { somethingNewItem, openKnowledgeEdit, knowledgeItems } =
    useLearnSomethingNew();

  return (
    <Page>
      <h1>Curriculum</h1>
      <Grid>
        {items().map((item) => {
          const count = state[item.id] || 0;
          return (
            <ModalButton
              key={item.id}
              onClick={() => {
                onClickItem(item);
              }}
            >
              {item.id} {"✋".repeat(count)}
            </ModalButton>
          );
        })}
      </Grid>
      <div style={{ height: "50px" }} />
      <Grid>
        <ModalButton onClick={() => onClickItem(somethingNewItem)}>
          Learn something new
        </ModalButton>
        <ModalButton onClick={openKnowledgeEdit}>What do I know?</ModalButton>
        {knowledgeItems.map((item) => (
          <ModalButton key={item.id} onClick={() => onClickItem(item)}>
            {item.id}
          </ModalButton>
        ))}
      </Grid>
    </Page>
  );
};

import styled, { css } from "styled-components";

export const StyledFormWrapper = styled.div<{ autoDict: boolean }>`
  display: grid;

  ${(props) =>
    props.autoDict &&
    css`
      grid-template-columns: 1fr 1fr;
    `};
`;

export const StyledForm = styled.form`
  box-sizing: border-box;
  width: 100%;
  max-width: 800px;
  margin: 0px auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: relative;

  .collection-button {
    position: static;
  }

  .context-input {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;
export const ForceTranslateButton = styled.button`
  border-radius: 50%;
  height: 30px;
  width: 30px;
  padding: 10px;
  cursor: pointer;
  background: #fff;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: lightgray;
`;
export const Adornments = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
`;
export const SearchIndicator = styled.div<{ active: boolean }>`
  cursor: pointer;
  margin-right: 10px;
  color: blue;
  font-size: 0.8em;
  ${(props) => props.active && "font-weight: bold;"}
`;
export const DesccriptionInside = styled.div`
  bottom: 20px;
  right: 20px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: end;

  * {
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.1s;
    font-size: 1.3em;

    &:hover {
      opacity: 1;
    }
  }

  a {
    margin: 10px 0;
  }
`;
export const DesctiptionInsideLeft = styled(DesccriptionInside)`
  right: unset;
`;
export const DesctiptionInsideLeft2 = styled(DesctiptionInsideLeft)`
  left: 60px;
`;

export const ExampleGeneratorButton = styled.span`
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.1s;
  font-size: 1.3em;

  &:hover {
    opacity: 1;
  }
`;

export const AssistantModal = styled.div`
  z-index: 9000;
  position: fixed;
  top: 20px;
  right: 20px;
  max-width: 600px;
  background: white;
  padding: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  font-size: 1.2em;
`;

export const AssistantClose = styled.button`
  all: unset;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  color: gray;
`;

export const FirstExample = styled.p`
  font-size: 0.9em;
  align-self: flex-start;
`;

import React, { useEffect, useState } from "react";
import { uploadImage } from "./uploadImage";

export function useClipboardImageUrl() {
  const [clipboardImageUrl, setClipboardImageUrl] = useState<string | null>(
    null
  );

  useEffect(() => {
    // If the browser does not support navigator.clipboard.read, skip
    if (!navigator.clipboard || !navigator.clipboard.read) {
      console.warn("Async Clipboard API not supported in this browser.");
      return;
    }

    let intervalId: ReturnType<typeof setInterval> | null = null;

    const checkClipboardForImage = async () => {
      try {
        const clipboardItems = await navigator.clipboard.read();
        // @ts-ignore
        let foundImageUrl = null;

        for (const item of clipboardItems) {
          for (const type of item.types) {
            if (type.startsWith("image/")) {
              const blob = await item.getType(type);
              // Convert the blob to a URL
              foundImageUrl = URL.createObjectURL(blob);
              break;
            }
          }
          if (foundImageUrl) break;
        }

        setClipboardImageUrl((prevUrl) => {
          // Revoke any previously set object URL to avoid memory leaks
          if (prevUrl) {
            URL.revokeObjectURL(prevUrl);
          }
          // @ts-ignore
          return foundImageUrl;
        });
      } catch (error) {
        console.error("Error reading from clipboard:", error);
        setClipboardImageUrl(null);
      }
    };

    // Poll every 100ms (though polling is often not ideal in production)
    intervalId = setInterval(checkClipboardForImage, 100);

    // Cleanup on unmount
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
      // Revoke the URL if we have one
      setClipboardImageUrl((prevUrl) => {
        if (prevUrl) {
          URL.revokeObjectURL(prevUrl);
        }
        return null;
      });
    };
  }, []);

  return clipboardImageUrl;
}

export const getPastedImage = async (
  event: React.ClipboardEvent<HTMLInputElement>
) => {
  if (!event.clipboardData || !event.clipboardData.items) {
    return null;
  }
  // @ts-ignore

  const items = event.clipboardData.items as any[];
  for (const item of items) {
    if (item.type.startsWith("image/")) {
      const file = item.getAsFile();
      return file;
    }
  }

  return null;
};

export const getClipboardImage = async (): Promise<File | null> => {
  if (!navigator.clipboard || !navigator.clipboard.read) {
    console.warn("Async Clipboard API not supported in this browser.");
    return null;
  }

  try {
    const clipboardItems = await navigator.clipboard.read();
    for (const item of clipboardItems) {
      for (const type of item.types) {
        if (type.startsWith("image/")) {
          const blob = await item.getType(type);
          // Create a File from the Blob.
          // Use the MIME type's subtype as the extension if you want.
          const extension = type.split("/")[1];
          const fileName = `clipboard_image.${extension}`;

          return new File([blob], fileName, { type });
        }
      }
    }
  } catch (error) {
    console.error("Error reading from clipboard:", error);
  }

  return null;
};

export const uploadClipboardImage = async () => {
  const file = await getClipboardImage();
  if (file) {
    return uploadImage(file, { width: 180 });
  }
  return null;
};

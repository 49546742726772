import React, { FC, useEffect, useRef, useState } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import styled, { css } from "styled-components";
import { asUntranslated, isTranslated } from "../util/translate";
import { getTranslationUrl } from "./TranslationLink";
import { useControls } from "./Controls/hooks";

const Adornment = styled.div`
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;

const FormInput = styled.input<{ big?: boolean; glowing?: boolean }>`
  box-sizing: border-box;
  width: 100%;
  padding: 15px;

  font-size: 1.1rem;
  transition: border 0.05s, box-shadow 0.1s ease-in-out;

  &:disabled {
    pointer-events: none;
  }

  ${(props) =>
    props.big &&
    css`
      font-size: 1.4rem;
    `}
  ${(props) =>
    props.glowing &&
    css`
      box-shadow: 0 0 10px rgba(0, 0, 255, 1);
      border: 1px solid blue;
    `}
`;
const InputWrapper = styled.div<{
  canEnable?: boolean;
  isAutoTranslated?: boolean;
  hasAdornment?: boolean;
}>`
  margin-bottom: 10px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  ${(props) =>
    props.canEnable &&
    `
    cursor: pointer;
    z-index: 1;
  `}
  ${(props) =>
    props.isAutoTranslated &&
    `
      input, textarea {
      color: blue;
      }
    `}

    ${(props) =>
    props.hasAdornment &&
    css`
      input,
      textarea {
        padding-right: 60px;
      }
    `}
`;

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  register: UseFormRegisterReturn;
  big?: boolean;
  disabler?: boolean;
  glowing?: boolean;
  textAreaRows?: number;
  voiceControlled?: boolean;

  onSubmit?(): void;

  adornment?: React.ReactNode;
  children?: React.ReactNode;
}

export const Input: FC<InputProps> = (props) => {
  const {
    register,
    adornment,
    children,
    disabler,
    big,
    onSubmit,
    voiceControlled,
    ...inputProps
  } = props;
  const { type = "text", ...finalInputProps } = inputProps;
  const voiceControl = useControls().voiceControlState[0];

  const customRef = useRef<HTMLInputElement | null>(null);
  const { ref: registerRef, onBlur, ...finalRegister } = register;

  const [disabled, setDisabled] = useState(disabler || false);
  const onWrapperClick = () => {
    setDisabled(false);
    setTimeout(() => customRef.current?.focus(), 0);
  };

  const [_isTranslated, setIsTranslated] = useState(false);
  useEffect(() => {
    checkAutoTranslated();
  }, [customRef.current?.value]);
  const checkAutoTranslated = () => {
    const value = customRef.current?.value;
    setIsTranslated(isTranslated(value));
  };

  useEffect(() => {
    if (finalInputProps.autoFocus) {
      onWrapperClick();
    }
  }, [finalInputProps.autoFocus]);

  useEffect(() => {
    if (voiceControlled && voiceControl) {
      const recognition = new (window.SpeechRecognition ||
        (window as any).webkitSpeechRecognition)();
      recognition.continuous = true;
      recognition.interimResults = true;

      recognition.onresult = (event: SpeechRecognitionEvent) => {
        const transcript = Array.from(event.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join("");
        if (customRef.current) {
          customRef.current.value = transcript;
        }
      };

      if (customRef.current) {
        customRef.current.addEventListener("focus", () => recognition.start());
        customRef.current.addEventListener("blur", () => recognition.stop());
      }

      return () => {
        if (customRef.current) {
          customRef.current.removeEventListener("focus", () =>
            recognition.start()
          );
          customRef.current.removeEventListener("blur", () =>
            recognition.stop()
          );
        }
        recognition.stop();
      };
    }
  }, [voiceControlled, voiceControl]);

  return (
    <InputWrapper
      onClick={onWrapperClick}
      canEnable={disabler && disabled}
      isAutoTranslated={_isTranslated}
      hasAdornment={!!adornment}
    >
      <div style={{ position: "relative" }}>
        <FormInput
          as={type === "textarea" ? "textarea" : "input"}
          rows={props.textAreaRows}
          big={big}
          type={type}
          onClick={onWrapperClick}
          {...finalInputProps}
          disabled={disabled}
          {...finalRegister}
          ref={
            // @ts-ignore
            (e) => {
              registerRef(e);
              customRef.current = e;
              checkAutoTranslated();
            }
          }
          onBlur={
            // @ts-ignore
            (e) => {
              onBlur(e);
              if (disabler) {
                setDisabled(true);
              }
            }
          }
          onFocus={
            // @ts-ignore
            (e) => {
              if (_isTranslated) {
                e.target.value = asUntranslated(e.target.value);
                setIsTranslated(false);
              }
            }
          }
          onKeyDown={
            // @ts-ignore
            (e) => {
              if (type !== "textarea") {
                return;
              }

              props.onKeyDown?.(e);

              if (e.key === "Enter" && !e.shiftKey && !props.textAreaRows) {
                e.preventDefault();
                onSubmit?.();
              }

              if (e.key === "@") {
                e.preventDefault();
                window.open(
                  getTranslationUrl(e.target?.value, "google"),
                  "_blank"
                );
              }
              if (e.key === "#") {
                e.preventDefault();
                window.open(
                  getTranslationUrl(e.target?.value, "deepl"),
                  "_blank"
                );
              }
            }
          }
        />
        {adornment && <Adornment>{adornment}</Adornment>}
      </div>
      {children && <div>{children}</div>}
    </InputWrapper>
  );
};

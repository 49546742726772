import { CurriculumItem, intro } from "./items";
import { useQuery } from "react-query";

const knowledgeId = "f6e5667d6af76cacea6b";
const knowledgeEdit = `https://www.npoint.io/docs/${knowledgeId}`;
const knowledgeBin = `https://api.npoint.io/${knowledgeId}`;
const openKnowledgeEdit = () => {
  window.open(knowledgeEdit, "_blank");
};
const fetchKnowledgeBin = async () => {
  const response = await fetch(knowledgeBin);
  const data = await response.json();
  return data as string[];
};

export const useLearnSomethingNew = () => {
  const { data: knowledge = [] } = useQuery("knowledgeBin", fetchKnowledgeBin);

  const somethingNewItem: CurriculumItem = {
    id: "learn-something-new",
    content: async () => {
      return (
        intro +
        `I already have a notion of the following advanced topics:\n${knowledge.join(
          ", "
        )}.
I want to learn something new. It can be a broader topic or just some interesting quirk, useful in my English super advanced journey.
Give it simple a title I can include in my knowledge base.`
      );
    },
  };

  const knowledgeItems: CurriculumItem[] = knowledge.map((item) => ({
    id: item,
    content: () =>
      intro +
      `I already know something about ${item}.
But want to practice more and/or refresh my memory.
If you give me exercises (my favourite is translating Polish sentences into English), please give me one at a time.`,
  }));

  return { somethingNewItem, openKnowledgeEdit, knowledgeItems };
};

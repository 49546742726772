import styled from "styled-components";
import { FC } from "react";
import { Toggle } from "./Toggle";
import { usePersistedState } from "../util/react";
import { ControlsState } from "./Controls/hooks";
import { Link } from "react-router-dom";

const ExtraControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
`;

const Btn = styled.button`
  height: 40px;
`;

const polThresholdOptions = ["-5", "1", "2", "4", "6", "10", "100"].map(
  (a) => ({
    value: a,
    label: a,
  })
);

interface ExtraControlsProps {
  controlsState: ControlsState;
  onClose: () => void;
}

export const ExtraControls: FC<ExtraControlsProps> = (props) => {
  const { onClose, controlsState } = props;

  const toggles = [
    { toggle: controlsState.silentState, label: "Silent" },

    {
      toggle: controlsState.searchEverywhereState,
      label: "Szukaj, ignorując inne filtry",
    },
    {
      toggle: controlsState.ringFavouriteState,
      label: "Nowy system ulubionych",
    },
    { toggle: controlsState.asyncLoaderState, label: "Ładuj fiszki miarowo" },
    { toggle: controlsState.speakThresholdState, label: "Speak threshold" },
    { toggle: controlsState.voiceControlState, label: "Voice control" },
    { toggle: controlsState.writingAssistantState, label: "Writing assistant" },
    { toggle: controlsState.autoDictState, label: "Auto dict" },
  ];

  const [algorithmPicker, setAlgorithmPicker] = usePersistedState(
    "algorithm-picker",
    true,
    sessionStorage
  );

  return (
    <ExtraControlsContainer>
      {toggles.map((t, index) => {
        const { label, toggle } = t;
        const [value, setValue] = toggle;
        const onToggle = () => setValue(!value);
        return (
          <Toggle key={label} value={value} toggle={onToggle} label={label} />
        );
      })}

      <hr />

      {!algorithmPicker && (
        <Btn
          type="button"
          onClick={() => {
            setAlgorithmPicker(true);
          }}
          style={{
            margin: "30px 0",
            display: "block",
          }}
        >
          Pokaż z powrotem wybór algorytmu
        </Btn>
      )}

      <hr />

      <div>
        <Link to={"/curriculum"}>Curriculum</Link>
      </div>

      <hr />

      <Btn onClick={onClose} type="button">
        Zamknij
      </Btn>
    </ExtraControlsContainer>
  );
};

import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const StyledConfigNavigation = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 400px;
  margin: 5px auto;
`;

const LinkStyled = styled(Link)<{ active: boolean }>`
  all: unset;
  cursor: pointer;
  text-align: center;
  margin: 2px;
  padding: 5px;

  ${({ active }) =>
    active &&
    `
    color: blue;
    `}
`;

export const SpeakNavigation = () => {
  const { pathname } = useLocation();
  return (
    <StyledConfigNavigation>
      <LinkStyled to={"/speak/topic"} active={pathname === "/speak/topic"}>
        Speak
      </LinkStyled>
      <LinkStyled to={"/speak/babble"} active={pathname === "/speak/babble"}>
        Speak v2
      </LinkStyled>
    </StyledConfigNavigation>
  );
};

import React, { createContext, PropsWithChildren, useState } from "react";
import { CollectionDto, FlashcardDto, QuizQuestionTopic } from "../../types";
import { getContexts } from "../../util/flashcards";
import { useFlashcardsLoader } from "./hooks";
import { Algorithm } from "../../util/algorithm";
import { usePersistedState } from "../../util/react";
import { useHeatometer, useQuizometer, useUsemeter } from "../../util/heat";
import { useCollections } from "../../util/collections";
import { ControlsState, useControlsState__ } from "../Controls/hooks";

export const AppContext = createContext({
  flashcards: [] as FlashcardDto[],
  setFlashcards: ((fls) => {}) as React.Dispatch<
    React.SetStateAction<FlashcardDto[]>
  >,
  loadingFlashcards: false,
  allContexts: [] as string[],
  currentlyPlayingId: null as string | null,
  setCurrentlyPlayingId(id: string | null) {},
  isOffline: false,
  algorithm: Algorithm.ALGORITHM_1,
  setAlgorithm(algorithm: Algorithm) {},
  ringAlgorithm: Algorithm.RING,
  setRingAlgorithm(algorithm: Algorithm) {},

  controlsState: {} as ControlsState,
  // --
  heat: 0,
  bumpHeat() {},
  // --
  uses: 0,
  bumpUses() {},
  // --
  getQuizometer(type: QuizQuestionTopic | "all"): number {
    return 0;
  },
  bumpQuizometer(type: QuizQuestionTopic) {},
  //
  collections: [] as CollectionDto[],
  toggleCollectionItem(
    collectionName: string,
    id: string,
    addOnly?: boolean
  ) {},
  currentCollection: "",
  setCurrentCollection(collection: string) {},
});

export const AppContextProvider = ({ children }: PropsWithChildren) => {
  const controlsState = useControlsState__();

  const [flashcards, setFlashcards] = useState<FlashcardDto[]>([]);
  const [currentlyPlayingId, setCurrentlyPlayingId] = useState<string | null>(
    null
  );
  const [isOffline] = useState(false);

  const [loadingFlashcards, setLoadingFlashcards] = useState(false);
  useFlashcardsLoader(
    controlsState.asyncLoaderState[0],
    setFlashcards,
    setLoadingFlashcards
  );

  const [algorithm, setAlgorithm] = usePersistedState(
    "algorithm",
    Algorithm.ALGORITHM_1
  );
  const [ringAlgorithm, setRingAlgorithm] = usePersistedState(
    "ring-algorithm",
    Algorithm.RING
  );

  const allContexts = getContexts(flashcards);

  return (
    <AppContext.Provider
      value={{
        flashcards,
        setFlashcards,
        loadingFlashcards,
        allContexts,
        currentlyPlayingId,
        setCurrentlyPlayingId,
        isOffline,
        algorithm,
        setAlgorithm,
        ringAlgorithm,
        setRingAlgorithm,
        controlsState,
        ...useCollections(),
        ...useHeatometer(),
        ...useQuizometer(),
        ...useUsemeter(),
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
